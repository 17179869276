import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Accordion from '../atoms/Accordion';
import ActiveLink from '../atoms/ActiveLink';
import { useAccount } from '@azure/msal-react';
import { differenceInYears } from 'date-fns';
import { CgPushChevronLeft } from 'react-icons/cg';
import { LuSettings } from 'react-icons/lu';
import { TbLayoutDashboard } from 'react-icons/tb';
import { twMerge } from 'tailwind-merge';
import { AiFillAlert } from 'react-icons/ai';
import SpeedometerChart from '../../assets/icons/speedometerchart.svg';
import {
  birthdayPortal,
  isPortalBirthday
} from '../../constants/funnyEvents/birthday';
import useAlertConfig from '@/hooks/Alerts/useAlertConfig';

type SideBarItems = {
  [key: string]: {
    name: string;
    items: {
      name: string;
      path: string;
    }[];
    icon?: React.ReactNode;
  };
};

const Sidebar: React.FC = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const user = useAccount();

  const { hasPermissionToEdit } = useAlertConfig();

  const defaultRoutes = {
    Home: {
      name: 'Home',
      items: [{ name: 'Página Inicial', path: '/home' }],
      icon: <TbLayoutDashboard className="flex-none" />
    },
    Operation: {
      name: 'Operação',
      items: [
        { name: 'Ficha do Poço', path: '/home/oilwell-info' },
        { name: 'Workflow de Testes', path: '/home/tests-workflow' },
        { name: 'Boletim Diário de Operações', path: '/home/bdo' },
        { name: 'Boletim Diário de Medições', path: '/home/bdmf' },
        { name: 'Permissões de Trabalho', path: '/home/work-permit' },
        { name: 'Plan. Integrado de Operações', path: '/home/piop' },
        { name: 'Painel de Intervenções', path: '/home/intervention-panel' }
      ],
      icon: <img src={SpeedometerChart} className="flex-none w-4" />
    },
    Alerts: {
      name: 'Alertas',
      items: [
        { name: 'Vazão', path: '/home/alerts' },
        { name: 'Pressão', path: '/home/alert-pressures' },
        { name: 'Poços Bombeados', path: '/home/elevation-alerts' },
        ...(hasPermissionToEdit
          ? [{ name: 'Parâmetros de Alertas', path: '/home/alerts/settings' }]
          : [])
      ],
      icon: <AiFillAlert />
    }
  };

  const handleChangeSideBar = () => {
    setIsOpen(old => !old);
  };

  const [items, setItems] = useState<SideBarItems>(defaultRoutes);

  const AdminRoutes = {
    name: 'Admin',
    icon: <LuSettings className="flex-none" />,
    items: [
      { name: 'Poços', path: '/oilwells' },
      { name: 'Campos', path: '/oilfields' },
      { name: 'Zonas', path: '/zones' },
      { name: 'Alocação Poço-Zona', path: '/oilwell-zone-allocation' },
      { name: 'Cromatografia GL', path: '/chroma' },
      { name: 'Satélites', path: '/satellites' },
      { name: 'Medidor de gás', path: '/gas-meter' },
      { name: 'Tanque', path: '/tank' },
      { name: 'Tags PI', path: '/pi-tags' }
    ]
  };

  const isAdmin = async () => {
    if (user?.idTokenClaims?.roles?.includes('Portal.Admin')) {
      setItems({
        ...defaultRoutes,
        Admin: AdminRoutes
      });
    }
  };

  useEffect(() => {
    isAdmin();
  }, [user]);

  const today = new Date();

  return (
    <aside
      className={`bg-white flex flex-col gap-2 px-3 pt-10 pb-2 h-screen duration-500 overflow-hidden transition-all relative ${
        isOpen ? 'w-[300px]' : 'w-16'
      }`}
    >
      <div className="flex">
        <img
          src="/logo.png"
          alt="Logo Origem"
          className={`-ml-1 ${
            !isOpen
              ? 'h-[50px] w-[140px] mb-[calc(2rem+15px)]'
              : 'h-[65px] w-[185px] mb-8'
          } resize-none max-w-none transition-all duration-500`}
        />

        {isPortalBirthday && (
          <img src="/bcake.jpg" className="h-[65px] object-cover" />
        )}
      </div>

      {isPortalBirthday && (
        <span
          className={twMerge(
            'font-pacifico text-3xl text-center w-[42px] text-primary absolute top-[2.75rem] left-[12px] transition-all duration-500',
            isOpen && 'top-[3rem] w-[58px] left-[12px] text-4xl'
          )}
        >
          {differenceInYears(today, birthdayPortal.start)}
        </span>
      )}

      {Object.keys(items).map(it => (
        <Accordion
          key={it}
          title={items[it].name}
          icon={items[it].icon}
          buttonClassName={`${
            items[it].items.map(sub => sub.path).includes(location.pathname)
              ? 'bg-btn-light'
              : ''
          }`}
          forceClose={!isOpen}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {items[it].items.length > 0 && (
            <div className="flex flex-col pl-[36px] gap-1 pt-2">
              {items[it].items.map(subItem => (
                <ActiveLink
                  className="flex-none text-sm"
                  key={subItem.name}
                  to={subItem.path}
                >
                  {subItem.name}
                </ActiveLink>
              ))}
            </div>
          )}
        </Accordion>
      ))}

      <div className="mt-auto flex items-center justify-between min-h-[60px]">
        <button
          className=" px-3 py-2 flex hover:bg-[#f5f5f5] bg-transparent border-none rounded text-primary items-center font-bold gap-2"
          onClick={handleChangeSideBar}
        >
          <CgPushChevronLeft
            className={`transition-all duration-200 ${!isOpen && 'rotate-180'}`}
          />
        </button>

        {isOpen && (
          <img
            src="/origem.png"
            alt="Logo Origem"
            className="w-[103px] resize-none max-w-none"
          />
        )}
      </div>
    </aside>
  );
};

export default Sidebar;
